<template>
  <v-container id="database-checker" fluid tag="section">
    <!-- <v-row>
      <v-col cols="2">
        <v-btn color="primary" @click="checkCity">
          <v-icon left>mdi-new-box</v-icon>
          {{ $t("Check") }}
        </v-btn>
      </v-col>
      <!-- <v-col cols="2">
        <v-btn color="secondary" @click="getStatus(item_id)">
          <v-icon left>mdi-refresh</v-icon>
          {{ $t("refresh") }}
        </v-btn>
      </v-col>
    </v-row> -->
    <template>
      <CDRDetailFilter v-on:onCDRFilterChange="onCDRFilterChange"
                       v-on:onRecheck="onRecheck"></CDRDetailFilter>
    </template>
   
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        multi-sort
        hide-default-footer
      >
        <template v-slot:item.name="{ item }">{{
          item.name+"(id:"+item.id+")"
        }}</template>
      </v-data-table>
    </v-row> 
  </v-container>
</template>

<script>
import CDRDetailFilter from "../filters/CDRDetailFilter";
import { mapState } from "vuex";

export default {
  data: (vm) => ({
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("city"),
        value: "city.name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("district"),
        value: "district.name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("code"),
        value: "code",
      },
      {
        sortable: false,
        text: vm.$i18n.t("region"),
        value: "name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land_keys"),
        value: "land_keys",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land_owner_1_count"),
        value: "land_owner_1_count",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land_owners_count"),
        value: "land_owners_count",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land_owners_total"),
        value: "land_owners_total",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land_creditor_1_count"),
        value: "land_creditor_1_count",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land_creditors_count"),
        value: "land_creditors_count",
      },
      {
        sortable: false,
        text: vm.$i18n.t("land_creditors_count"),
        value: "land_creditors_total",
      },
    ],
    filterItem: {},
  }),
  mounted() {},
  computed: {
    ...mapState(["token"]),
  },
  components: {
    CDRDetailFilter,
  },
  methods: {
    checkCity(city, district) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/db_checker/valdate_cd/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          city: city,
          district: district,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.showMessage(response.data);
          // currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    onCDRFilterChange(item) {
      this.filterItem = this._.cloneDeep(item);

      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/regions/details/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          is_valid: 1,
        },
      };
      for (const [key, value] of Object.entries(this.filterItem)) {
        if (!this.$common_util.isEmpty(value)) {
          config.params[key] = value;
        }
      }
      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.items = response.data;
          currentObj.totalItems = response.data.length;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    onRecheck(item) {
      console.log("recheck the city/region " + JSON.stringify(item));
      this.checkCity(item["city__code"], item["district__code"]);
    },
    showMessage(data) {
      let info = JSON.parse(JSON.stringify(data));
      let status = info["status"];
      if (status == 0 || status == "0") {
        let msg = info["msg"];
        alert("Error:" + msg);
      } else {
        alert(info["cd"] + " is Checked.");
      }
    },
  },
};
</script>
